
@font-face {
  font-family: 'principalFontBold';
  src:url('fonts/MichelinUnitText1-Bold.woff2') format('woff2'),
  url('fonts/MichelinUnitText1-Bold.woff') format('woff');
}

@font-face {
  font-family: 'principalFont';
  src:url('fonts/MichelinUnitText1-Regular.woff2') format('woff2'),
  url('fonts/MichelinUnitText1-Regular.woff') format('woff');
}

@font-face {
  font-family: 'headlines';
  src:url('fonts/MichelinUnitTitling1-Bold.woff2') format('woff2'),
  url('fonts/MichelinUnitTitling1-Bold.woff') format('woff');
}
